import React from "react";
// import CancelAppointment from "./modals/CancelAppointment";
import Overlay from "./Overlay";
// import { AppointmentSelectionModal } from "./modals/AppointmentSelectionModal";
import Banner from "../types/Banner";
import SystemAPI from "../network/SystemAPI";
import { withTranslation, Trans } from 'react-i18next';
import { sweetalert } from "../App";

interface NavState {
    showLoading: boolean
    banner?: Banner
    logo: string
    brandingImage: string
    adminNavBackgroundColorHexValue: string
    productName
    selectedLanguage
}
class Nav extends React.Component<{ t }, NavState> {

    constructor(props) {
        super(props);
        this.state = {
            showLoading: false,
            logo: "",
            brandingImage: "",
            adminNavBackgroundColorHexValue: "",
            productName: "",
            selectedLanguage: 'en'
        }
        this.getBannerElem = this.getBannerElem.bind(this);
    }

    componentDidMount() {
        this.setState({ showLoading: true }, () => {
            let currentURL = window.location.href;
            SystemAPI.getProductBrandingFromURL(currentURL).then(data => {
                let ProductID = data.ProductID;
                let backgroundImageURL = data.ProductBackgroundImageURL;

                //@ts-ignore
                document.body.style.backgroundImage = `url(${backgroundImageURL}`;
                document.body.style.backgroundRepeat = "no-repeat";
                document.body.style.backgroundSize = "cover";

                this.setState({
                    brandingImage: data.ProductAdminNavLogoURL,
                    productName: data.Name,
                    adminNavBackgroundColorHexValue: data.ProductAdminNavBackgroundColorHexValue,
                    showLoading: false
                });
            });
        });
    }

    getBannerElem() {
        if (!this.state.banner)
            return null;
        return (
            <div className="alert alert-danger m-0" role="alert">
                <p className="primary-black m-0">
                    {this.state.banner.Text}
                </p>
            </div>
        )
    }

    getMedicalApprovalForm() {
        sweetalert
            .fire({
                icon: "question",
                input: "select",
                inputOptions: {
                    english: "English",
                    spanish: "Spanish",
                },
                inputValue: document.documentElement.lang === 'en' ? "english" : "spanish",
                confirmButtonText: "Download",
                showCancelButton: true,
                html: `<p>Please select your preferred language.</p>`,
                inputValidator: (value) => {
                    if (!value) {
                        return "You must select a language.";
                    }
                },
            })
            .then((result) => {
                if (result.isConfirmed) {
                    let env = process.env.REACT_APP_ENV_FOR_LOGIN
                    let fileURL = result.value === 'english' ? 'IMMY_MedicalExemptionRequest_English.pdf' : 'IMMY_MedicalExemptionRequest_Spanish.pdf';
                    const link = document.createElement('a');
                    link.href = `https://storage.googleapis.com/uploaded_document_bucket/${env === 'dev' ? 'development' : env}/OklahomaStateDepartmentofHealth/${fileURL}`;
                    link.target = '_blank';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
            });
    }


    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return (
            <React.Fragment>
                {/* <AppointmentSelectionModal /> */}
                <Overlay show_loading={this.state.showLoading} />
                {/* <CancelAppointment /> */}

                <div className="headerWrapper">
                    {this.getBannerElem()}
                    <div className="container-fluid p-1" style={{ backgroundColor: this.state.adminNavBackgroundColorHexValue ? this.state.adminNavBackgroundColorHexValue : 'black' }}>
                        <nav className="navbar navbar-expand-lg navbar-light">
                            <a className={!this.state.brandingImage || this.state.brandingImage.length < 1 ? 'd-none' : 'navbar-brand nav-href verlag-bold'}
                                href={"/"}>
                                <img src={this.state.brandingImage}
                                    className={'admin-nav-logo pb-1 pl-1'}
                                    alt={this.state.productName} />
                            </a>
                            <div className="row mx-0 align-items-center">
                                <div className="col-auto">
                                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
                                        <span className="navbar-toggler-icon"></span>
                                    </button>
                                </div>
                            </div>
                            <div className="collapse navbar-collapse float-right" id="navbarText">
                                <ul className="navbar-nav mr-auto text-center">
                                    <li className={"nav-item"}>
                                        <a className="nav-link text-dark" href={"/login"}>
                                            {this.props.t('requestAnEmemption')}
                                        </a>
                                    </li>
                                    <li className={"nav-item"}>
                                        <a className="nav-link text-dark" rel="noopener" target='_blank' onClick={() => this.getMedicalApprovalForm()} >
                                            {this.props.t('Download Medical Approval Form')}
                                        </a>
                                    </li>


                                </ul>
                            </div>
                        </nav>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default withTranslation()(Nav);