import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'

interface AcknowledgementProps {
  onSubmit?
  onAcknowledge?
  t
}

class Acknowledgement extends Component<AcknowledgementProps, any> {
  constructor(props) {
    super(props)
    this.state = {
      acknowledged: false
    }
  }
  render() {
    return (
      <div className="card mb-2  mt-2">
        <div className="card-header verlag-bold">
          <h4>{this.props.t("Acknowledgement")}</h4>
        </div>

        <div className="card-body">
          <div className="form-check ">
            <input className="form-check-input mt-2" aria-label='Legal acknowledgement' aria-labelledby='Acknowledgement' type="checkbox" id="acknowledge"
              checked={this.state.acknowledged}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  // @ts-ignore
                  this.setState({ acknowledged: !this.state.acknowledged }, () => this.props.onAcknowledge(!this.state.acknowledged))
                }
              }}
              onChange={(e) => {
                this.setState({ acknowledged: !this.state.acknowledged }, () => this.props.onAcknowledge(this.state.acknowledged))
              }} />
            <label id='Acknowledgement' className="form-check-label" htmlFor="acknowledge">
              <p>{this.props.t(`I understand that in the event of a disease outbreak in the School, Child Care Facility or Head Start, my child may have to be excluded for his/her protection and for the protection of the other children in the School, Child Care Facility or Head Start.`)}</p>
              <p>{this.props.t(`I acknowledge all of the provided information is accurate and I am either the parent or legal guardian for listed child.`)}</p>
              <p>{this.props.t(`I acknowledge that any approved vaccine exemption requests will be uploaded into the Oklahoma State Immunization Information System (OSIIS) under the submitted child’s record.`)}</p>
              <p>{this.props.t(`If approved, I am responsible for submitting the approved certificate to the child’s school, Child Care or Head Start program.`)}</p>
            </label>
          </div>
        </div>
        <div className={"card-footer"}>
          <button className={"btn btn-outline-primary "} onClick={this.props.onSubmit}>{this.props.t("Submit")}</button>
        </div>
      </div>);
  }
}

export default withTranslation()(Acknowledgement);