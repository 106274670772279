import NetworkUtil from "./NetworkUtil";
import {ServerResponse} from "../types/ServerResponse";

export default class ExemptionAPI {

  public static async filterExemptions(filter):Promise<{success:boolean, reason:string, data}>{
      return NetworkUtil.makePost("/api/admin/exemption/filter", filter)
  }
  
  public static async newExemption(exemptionInfo, ApprovalLetter):Promise<ServerResponse>{
    let data = new FormData()
    data.set('exemptionInfo', JSON.stringify(exemptionInfo))
    if(ApprovalLetter){
            data.append('approvalLetter', ApprovalLetter)
    }

    return await NetworkUtil.postForm("/api/admin/exemption/new", data);
  }

  public static async getExemptionCertificate(reqNum):Promise<any>{
    return await NetworkUtil.makePostForBuffer(`/api/admin/pdfReport/getLatestCertificateForReqNum`, {reqNum})
  }

  public static viewCertificate(path: string): Promise<any> {
    return NetworkUtil.makePostForBuffer("/api/admin/pdfReport/viewCertificate", { path: path });
  }

  // returns filePath of result report that is generated and stored in the cloud
  public static generateCertificateForReqNum(reqNum: string): Promise<{ success: boolean, reason: string, data }> {
    return NetworkUtil.makePost("/api/admin/pdfReport/generateResultReportForReqNum", { reqNum: reqNum })
  }
}