import { useContext, useEffect, useRef, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { sweetalert } from "../../App";
import { HintModal } from "../modals/HintModal";
import { showModalNoOutsideClick } from "../../util/FormatUtil";
import { FaInfoCircle } from "react-icons/fa";
import {
    signInWithEmailAndPassword,
    verifyPasswordResetCode,
    confirmPasswordReset,
    setPersistence,
    browserSessionPersistence,
    PhoneAuthProvider,
    PhoneMultiFactorGenerator,
    RecaptchaVerifier,
    getMultiFactorResolver,
    getIdToken,
    signOut,
    UserCredential,
    updatePassword,
    createUserWithEmailAndPassword,
} from "firebase/auth"
import { auth } from "../../FirebaseConfig";
import AdminAPI from "../../network/AdminAPI";
import { AuthContext } from "../../context/AuthContext";
import { getToken } from "../../util/CaptchaUtil";
import UsersAPI from "../../network/UsersAPI";
import { AiFillEye } from "react-icons/ai";
import PhoneInput, {isValidPhoneNumber} from 'react-phone-number-input'
import { UserContext } from "../../types/UserContext";
import SystemAPI from "../../network/SystemAPI";
import { DefaultPasswordConfiguration, PasswordConfigurationValues } from "../../types/PasswordConfig";
import InBetweenOverlay from "../InBetweenOverlay";
import { useTranslation, Trans } from 'react-i18next';
import LanguageSwitcher from "../LanguageSwitcher";

export default function Login({ form_action }) {
    const userContext = useContext(AuthContext);
    let navigate = useHistory();
    const { t } = useTranslation();

    let currentPathname = window.location.pathname;
    let pathnameRef = useRef(currentPathname) // changed const to let, what does this do?

    let formIsPasswordReset = pathnameRef.current.includes('resetPassword');
    let formIsEmailPasswordReset = pathnameRef.current.includes('action');
    let formIsLogin = pathnameRef.current.includes('login');
    let formIsEnrollMFA = pathnameRef.current.includes('enrollMFA');
    let formIsRegister = pathnameRef.current.includes('register');
    
    const [email, setEmail] = useState(null);
    const emailSearchParam = new URLSearchParams(new URL(window.location.href).search).get('email');
    const emailRef = useRef(emailSearchParam ? emailSearchParam : userContext?.email ? userContext?.email : null);

    const [password, setPassword] = useState(null);
    const [rememberMe, setRememberMe] = useState(true);
    const [showPassword, setShowPassword] = useState(false)
    const [aiFillEyeColor, setAiFillEyeColor] = useState(false)
    const [aiFillEyeColor2, setAiFillEyeColor2] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState(null);
    const [firstName,setFirstName] = useState(null);
    const [lastName,setLastName] = useState(null)

    let [pswdAttempts, setPswdAttempts] = useState(null)
    const [pswdConfigs, setPswdConfigs] = useState(null);
    const [hintModal, setHintModal] = useState({hintHeader: t("Password Requirements"), hintContent: ""});

    const [logoURL, setLogoURL] = useState('');
    const [productName, setProductName] = useState('');
    const [buttonColorHexValue, setButtonColorHexValue] = useState('')
    const [backgroundColorHexValue, setBackgroundColorHexValue] = useState('')
    const [selectedLanguage, setSelectedLanguage] = useState('en')


    const [showLoading, setShowLoading] = useState(false)

    // Form Validations
    const [form, setForm] = useState({
        username:null,
        password:null,
        confirmPassword:null,
        phoneNumber:null,
        firstName:null,
        lastName:null
    })
    const { register, handleSubmit, setFocus, formState: { errors }, trigger, reset, watch, getValues } = useForm({
        mode: "onTouched"
    });
    const [isReset, setReset] = useState(false);
    
    // empty dependency array makes useEffect() run like componentDidMount
    useEffect(() => {
        document.body.style.backgroundColor = 'black';

        setShowLoading(true)

        let currentURL = window.location.href;

        SystemAPI.getProductBrandingFromURL(currentURL).then(data => {
            let backgroundImageURL = data.ProductBackgroundImageURL;

            //@ts-ignore
            document.body.style.backgroundImage = `url(${backgroundImageURL}`;
            document.body.style.backgroundRepeat = "no-repeat";
            document.body.style.backgroundSize = "cover";

            setLogoURL(data.ProductLogoURL);
            setProductName(data.Name)
            setButtonColorHexValue(data.ProductButtonColorHexValue)
            setBackgroundColorHexValue(data.ProductAdminNavBackgroundColorHexValue)
            setShowLoading(false)
        })



        //use the front part of url orapath.immytech.com to find matching 

        setForm({
            username: emailRef.current ? emailRef.current : userContext?.email ? userContext?.email : null,
            password: null, 
            confirmPassword: null, 
            phoneNumber: null,
            firstName:null,
            lastName: null,
        });

        getPasswordConfig(); // used in password/confirm password validations

        // keep track of login attempts
        setPswdAttempts(0);



        // set up reCAPTCHA for firebase auth
        if (!userContext && !window['recaptchaVerifier']) {
            window['recaptchaVerifier'] = new RecaptchaVerifier('recaptcha-container', {
                size: 'invisible',
                callback: function (response) {
                    // console.log(' recaptchaVerifier callback', response)
                }
            }, auth);
        }
    }, []) //<= runs on first load

    // react-hook-form
    useEffect(() => {
        // console.log(' [reset, form] ')
        reset(form);
        setReset(true);
    }, [reset, form]); // <= runs when reset state or form state updates
    useEffect(() => {
        // console.log(' [isReset]')
        isReset && trigger()
    }, [isReset]) // <= runs when trigger state or isReset state updates

    // if user is logged in and hits /login route, redirect to /admin
    useEffect(()=>{
        // console.log("[userContext,action]")
        // console.log('errors', errors)
        if(userContext && form_action === 'login'){
            navigate.push("/admin")
        }
        if(!userContext && form_action === 'login'){
            setForm({
                username: emailRef.current ? emailRef.current : userContext?.email ? userContext?.email : null,
                password: null, 
                confirmPassword: null, 
                phoneNumber: null,
                firstName: null,
                lastName: null,
            });
        }
        if(userContext && form_action === 'exemption'){
            navigate.push("/admin/VaccineExemptions")
        }
        if(userContext && form_action === 'enrollMFA'){
            setFocus("phoneNumber")
        }
    },[userContext,form_action,setFocus])

    async function composeRegExp(pswdConfigValues){
        let upper = pswdConfigValues.numUpper;
        let symbol = pswdConfigValues.numSymbols;
        let digits = pswdConfigValues.numDigits;
        let char = pswdConfigValues.numChar;
        // build regex string
        
        let startAnchor = `^`
        let newUpper = `(?=(.*[A-Z]){${upper}})`
        let newSymbol = `(?=(.*[!@#$%^&*]){${symbol}})`
        let newDigits = `(?=(.*[0-9]){${digits}})`
        let newChar = `.{${char},}`
        let endAnchor = `$`

        let regExpString = startAnchor + newUpper + newSymbol + newDigits + newChar + endAnchor;

        // build message 
        // console.log('char', char)
        let message = `Password should contain at least ${char} characters `;

        if ((upper  && upper  !== "0") ||
            (symbol && symbol !== "0") || 
            (digits && digits !== "0")
        ){
            message += `and include `
        }

        if(upper && upper !== "0"){ message += `${upper} uppercase (A-Z)`}
        if(symbol && symbol !== "0"){ message += `, ${symbol} symbol (!@#$%^&*)`}
        if(digits && digits !== "0"){ message += `, ${digits} digits (0-9)`}

        // console.log('message', message)
        
        return {
            configMessage: message,
            configString: regExpString
        }
    }

    async function getPasswordConfig(){
        try {
            let settings = await SystemAPI.getPasswordRequirementConfig();
            let pswdConfigValues:PasswordConfigurationValues = settings?.data;
            let regex = await composeRegExp(pswdConfigValues);
            setHintModal({...hintModal, hintContent: t(regex.configMessage)})
            setPswdConfigs({
                required: DefaultPasswordConfiguration.required,
                minLength: { 
                    value: Number(pswdConfigValues.numChar), 
                    message: `Password should contain at least ${pswdConfigValues.numChar} characters`,
                },
                pattern: {
                    value: new RegExp(regex.configString), // value must be a RegExp not a string

                    //TODO: add t here
                    message: t(`${regex.configMessage}`)
                }
            });
        } catch (e) {
            console.error(e)
            displayFriendlyError(e);
        }
    }

    // https://firebase.google.com/docs/auth/admin/errors
    function displayFriendlyError(error){
        console.error(error)
        let msg = "";

        if(pswdAttempts === 2){
            msg = "Warning: too many failed log in attempts will temporarily disable your account.";
        }
        if(error.message.includes('wrong-password')){
            msg = "The provided value for the password is invalid.";
            setPswdAttempts(pswdAttempts++)
            // console.log(' pswdAttempts',pswdAttempts)
        }
        else if(error.message.includes('user-not-found')){
            msg = "There is no user record corresponding to the provided email.";
        }
        else if(error.message.includes('email-already-exists')){
            msg = "The provided email is already in use by an existing user.";
        }
        else if(error.message.includes('invalid-email')){
            msg = "The provided value for the email property is invalid.";
        }
        else if(error.message.includes('phone-number-already-exists')){
            msg = "The provided phone number is already in use by an existing user.";
        }
        else if(error.message.includes('invalid-verification-code')){
            msg = "The provided verification code is invalid.";
        }
        else if(error.message.includes('too-many-requests')){
            msg = "Access to this account has been temporarily disabled due to many failed login attempts. " + 
                "You can immediately restore it by resetting your password or you can try again later.";
        }
        else if(error.message.includes('id-token-revoked')){
            return sweetalert.fire({icon:'warning',text:t("Please log in to re-authenticate your session")})
                .then(()=>{
                    navigate.push("/login")
                });
        }
        else if(error.message.includes('user-disabled')){
            msg = "This account had been disabled.  Please contact an adminstrator."
        }
        else {
            msg = "Error with login. " + error.message + "\n\n" + "Please try again later.";
        }

        //TODO: add t to msg
        return sweetalert.fire({text: t(`${msg}`), icon: 'error'})
    }

    const onError = (error)=>{
        console.error(error)
    }

    const onSubmit = async (data,e) => {
        e.preventDefault();
        getToken();   // google enterprise captcha

        if(form_action=== 'login' || formIsLogin) {
            // console.log(" try signInWithEmailAndPassword")

            if (!rememberMe) { await setPersistence(auth, browserSessionPersistence) }

            try {
                let logInUser = await signInWithEmailAndPassword(auth, email, password);
                // console.log(" logInUser ", logInUser);
                let idToken = await getIdToken(logInUser.user);

                const userCredential = await AdminAPI.getAuthenticated({token: idToken});
                let userProfile:UserContext = userCredential.user;
                // console.log(' !!! userProfile', userProfile)
                if (userProfile.Status === 'New' ||
                    userProfile.DefaultPassword !== null){
                        sweetalert.fire({
                            icon: 'info',
                            text: t('Please reset your password.  ', {'pswdConfigs.pattern.message': pswdConfigs.pattern.message})
                        }).then(()=>{
                            navigate.push("/__/auth/resetPassword?email=" + userProfile.Email)
                        }).catch((e)=>{
                            console.error(e)
                            displayFriendlyError(e)
                        })
                } else if(!userProfile.EnrolledMFA){
                    sweetalert.fire({
                        icon: 'info',
                        text: t('Please enter your phone number to enroll in multi-factor verification.')
                    }).then(()=>{
                        navigate.push("/__/auth/enrollMFA?email="+userProfile.Email)
                    }).catch((e)=>{
                        console.error(e)
                        displayFriendlyError(e)
                    })
                } else if (userProfile && userProfile.UserRoles.includes("OSDH-IMM")) {
                  // console.log(" user is not PUBLIC ", userProfile)
                  return sweetalert.fire({
                    icon: 'info',
                    text: t('User Role is not a Public role. Please sign into our Admin portal.')
                  }).then(() => {
                    return signOut(auth).then(() => {
                      // redirect user to admin portal
                      const loginEnv = process.env.REACT_APP_ENV_FOR_LOGIN;
                      let adminPortalURL = `imm.osdh.immytech.com`;
                      let domain = loginEnv === 'production' ? `https://${adminPortalURL}` : `https://${loginEnv}.${adminPortalURL}`;

                      window['location'] = domain + '/login' as unknown as Location;
                    });
                  }).catch((e) => {
                    console.error(e)
                    displayFriendlyError(e)
                  })
                } else { 
                    // console.log(" navigating to admin ");
                    // return <Redirect to="/admin" />;
                    navigate.push("/admin")
                }
            } catch (error) {
                console.error(error);
                // we catch this error on purpose to ensure user is prompted with MFA
                if (error.code === 'auth/multi-factor-auth-required') {
                    // console.log(" call recaptchaVerifier verify() and set widgetId")
                    await window['recaptchaVerifier'].verify().then(widgetid => {
                        window['recaptchawidgetid'] = widgetid
                    });

                    const resolver = getMultiFactorResolver(auth, error);
                    let selectedIndex = 0; // we only have one option for MFA right now
                    if (resolver.hints[selectedIndex].factorId === PhoneMultiFactorGenerator.FACTOR_ID) {
                        const phoneInfoOptions = {
                            multiFactorHint: resolver.hints[selectedIndex],
                            session: resolver.session
                        }
                        const phoneAuthProvider = new PhoneAuthProvider(auth);
                        // verify phone number, recaptcha should be invisible
                        let verificationId = await phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, window['recaptchaVerifier'] )
                        // popup to verify sms code
                        let { value: verificationCode } = await sweetalert.fire({
                            input: 'text',
                            inputLabel: t('Please enter the 6-digit verification code to continue'),
                            inputValidator: (value) => {
                                if (!value) {
                                    return t("Please enter the 6-digit verification code")
                                }
                            }
                        })
                        const phoneAuthCredential = PhoneAuthProvider.credential(verificationId, verificationCode)
                        try {
                            const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(phoneAuthCredential)
                            // threw error here when invalid verificationCode is passed in => need to catch this!!!!
                            const mfa_user:UserCredential = await resolver.resolveSignIn(multiFactorAssertion);
                            // console.log('mfa_user',mfa_user)
                            // console.log(" userContext?",userContext)
                            // user signed in with MFA
                            let idToken = await getIdToken(mfa_user.user);

                            const userCredential = await AdminAPI.getAuthenticated({token:idToken});
                            let userProfile = userCredential.user;

                            if (userProfile.Status === 'New' || 
                                userProfile.DefaultPassword !== null) {
                                sweetalert.fire({
                                    icon: 'info',
                                    text: t('Please reset your password.  ', {'pswdConfigs.pattern.message': pswdConfigs.pattern.message})
                                }).then(() => {
                                    navigate.push("/__/auth/resetPassword?email=" + userProfile.Email)
                                }).catch((e) => {
                                    console.error(e)
                                    displayFriendlyError(e)
                                })
                            } else {
                                // console.log(" navigate to admin ");
                                navigate.push("/admin")
                            }
                        } catch(mfaAssertionError){
                            return displayFriendlyError(mfaAssertionError)
                        }
                    }
                } else {
                    return displayFriendlyError(error)
                }
            }
        }
        if(form_action === 'action' || formIsEmailPasswordReset){ // from email link
            try {
                const url = new URL(window.location.href)
                let mode = new URLSearchParams(url.search).get('mode');
                let oobCode = new URLSearchParams(url.search).get('oobCode');
                if(mode === 'resetPassword' && oobCode){
                    // from email link
                    let validCode = await verifyPasswordResetCode(auth, oobCode) // this returns the users email if code is valid
                    if(!validCode){ return sweetalert.fire({text: t('Invalid action code. Please try resetting your password again.')}); }
                    if(validCode !== form.username){
                        throw Error(t('Unable to confirm password reset for username: ', {"form.username": form.username}))
                    }
                    return confirmPasswordReset(auth, oobCode, password)
                        .then((response)=>{
                            return sweetalert.fire({ icon: 'success', text: t('Thank you. Please login with your new password.') })
                                .then(()=>{
                                    signOut(auth).then(()=>{
                                        window['location'] = '/login' as unknown as Location;
                                        // return <Redirect to="/login" />
                                        // navigate.push('/login')
                                    });
                                });
                        })
                        .catch((error)=>{
                            return sweetalert.fire({icon:'error',text:t('Unable to confirm password reset. Please try resetting your password again.')})
                        })
                }
            } catch (error) {
                console.error(error);
                return displayFriendlyError(error);
            }
        }

        if(form_action === 'resetPassword' || formIsPasswordReset){ // from resetPassword redirect
            // new user login reset default password
            try {
                updatePassword(userContext, password).then(async (response)=>{
                    let db_response = await UsersAPI.resetNewUserPassword(userContext.uid, password);
                    if (!db_response || !db_response.success) {
                        return sweetalert.fire({ icon: 'error', text: t('Unable to reset password. Try again later.') })
                            .then(()=>{
                                signOut(auth).then(()=>{
                                    navigate.push("/loggedout");
                                });
                            })
                    }

                    if( db_response.success ){
                        return sweetalert.fire({ icon: 'success', text: t('Thank you. Your password has been updated.')})
                            .then(async (response)=>{
                                let idToken = await getIdToken(userContext);
                                const userCredential = await AdminAPI.getAuthenticated({ token: idToken });

                                if (userCredential.success === false || !userCredential.user) {
                                    sweetalert.fire({
                                        icon: 'warning',
                                        text: t('Please log in to re-authenticate your session.')
                                    }).then(() => {
                                        navigate.push('/login');
                                    });
                                }
                                let userProfile: UserContext = userCredential?.user;
                                if (userProfile?.EnrolledMFA === false) {
                                    // need to enroll in MFA
                                    sweetalert.fire({
                                        icon: 'info',
                                        text: t('Please enter your phone number to enroll in multi-factor verification.')
                                    }).then(() => {
                                        navigate.push("/__/auth/enrollMFA?email=" + userProfile.Email);
                                    }).catch((e) => {
                                        console.error(e);
                                        sweetalert.fire({
                                            icon: 'warning',
                                            text: t('Please log in to re-authenticate your session.')
                                        }).then(() => {
                                            navigate.push('/login');
                                        });
                                    });
                                }
                                else {
                                    navigate.push('/admin')
                                }
                            })
                        }
                })

            } catch(e){
                return displayFriendlyError(e)
            }
        }
        if(form_action=== 'enrollMFA'){ // from enrollMFA redirect
            try {
                let addPhoneNumber = await UsersAPI.setUserEnrolledMFA(userContext,phoneNumber)
                let logInUser = await signInWithEmailAndPassword(auth, form.username, form.password); //logInUser is firebaseUser
            }catch(e){
                if (e.code === 'auth/multi-factor-auth-required') {
                    // console.log(" call recaptchaVerifier verify() and set widgetId")
                    await window['recaptchaVerifier'].verify().then(widgetid => {
                        window['recaptchawidgetid'] = widgetid
                    });

                    const resolver = getMultiFactorResolver(auth, e);
                    let selectedIndex = 0; // we only have one option for MFA right now
                    if (resolver.hints[selectedIndex].factorId === PhoneMultiFactorGenerator.FACTOR_ID) {
                        const phoneInfoOptions = {
                            multiFactorHint: resolver.hints[selectedIndex],
                            session: resolver.session
                        }
                        const phoneAuthProvider = new PhoneAuthProvider(auth);
                        // verify phone number, recaptcha should be invisible
                        let verificationId = await phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, window['recaptchaVerifier'] )
                        // popup to verify sms code
                        let { value: verificationCode } = await sweetalert.fire({
                            input: 'text',
                            inputLabel: t('Please enter the 6-digit verification code to continue'),
                            inputValidator: (value) => {
                                if (!value) {
                                    return t("Please enter the 6-digit verification code")
                                }
                            }
                        })
                        const phoneAuthCredential = PhoneAuthProvider.credential(verificationId, verificationCode)
                        try {
                            const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(phoneAuthCredential)
                            // threw error here when invalid verificationCode is passed in => need to catch this!!!!
                            const mfa_user = await resolver.resolveSignIn(multiFactorAssertion);
                            // console.log('mfa_user',mfa_user)
                            // user signed in with MFA
                            let idToken = await getIdToken(mfa_user.user);
                            const userCredential = await AdminAPI.getAuthenticated({token:idToken});
                            let userProfile:UserContext = userCredential.user;
                            let mfaEmail = await UsersAPI.sendEnrollMFAEmail(userProfile,phoneNumber);
                            if(!mfaEmail.success){
                                return sweetalert.fire({title: 'Error', text: t('Failed to send MFA Enrollment email'), icon: 'error'})
                            }
                            navigate.push("/admin")
                        }catch(mfaAssertionError){
                            return displayFriendlyError(mfaAssertionError)
                        }
                    }
                } else {
                    displayFriendlyError(e)
                }
            }
        }
        if(form_action === 'register'){
            // TODO: register the user for the patient portal
            try {
                createUserWithEmailAndPassword(auth, email, password)
                    .then((userCredential) => {
                        // Signed in 
                        const firebaseUser = userCredential.user;

                        //need to create a User
                        // { UUID, FirstName, LastName, DisplayName, Email, Status, CustomerID, UserRoles,PhoneNumber,EnrolledMFA}
                        let newUser = {
                            FirstName: form.firstName,
                            LastName: form.lastName,
                            Email: form.username,
                            Password: form.password,
                            UUID: firebaseUser.uid,
                            UrlString: window.location.href
                        }
                        UsersAPI.createPatientAccount(newUser).then((response) => {
                            //need to create a PatientProfile
                            // { uid, firstName,middleName,lastName, displayName,email,phoneNumber,dateOfBirth,streetAddress,streetAddress2,city,state,zipcode,country,county,customerID,genderID,ethnicityID,raceID,guardianFirstName,guardianLastName}
                            return sweetalert.fire({
                                icon: 'success',
                                title: t('Thank you for registering.'),
                                text: t('Please take the time to fill out your profile information before submitting a request for vaccine exemption.')
                            }).then(() => {
                                signInWithEmailAndPassword(auth, email, password).then((logInUser) => {
                                    getIdToken(logInUser.user).then(async (idToken) => {
                                        const credential = await AdminAPI.getAuthenticated({ token: idToken })
                                        let userProfile: UserContext = credential.user;
                                        navigate.push('/admin/profilemanagement');
                                    });
                                });
                            });
                        })
                    })
                    .catch((error) => {
                        console.error(error);
                        let msg;
                        if(error.message.includes('email-already-in-use')){
                            msg = "The provided email is already in use by an existing user.";
                        } else {
                            msg = "Registration could not be completed at this time. Please try again later."
                        }

                        //TODO: add t to msg
                        return sweetalert.fire({text: t(`${msg}`), icon: 'error'});
                    });
            } catch(e){
                return displayFriendlyError(e)
            }
        }
    }

    function togglePassword(e, key){
        let x = document.getElementById(key) as HTMLInputElement;
        if(showPassword){
            x.type = "password";
            setShowPassword(false);
            if(key === 'password') setAiFillEyeColor(false);
            if(key === 'confirmPassword') setAiFillEyeColor2(false);
        } else{
            x.type = "text";
            setShowPassword(true)
            if(key === 'password') setAiFillEyeColor(true);
            if(key === 'confirmPassword') setAiFillEyeColor2(true);
        }
    }

    return (
      <>
        <InBetweenOverlay showInBetween={true} zIndex={-10}/>
        <div className={showLoading ? "d-none" : "container  min-vh-100"}>
            <div className="row justify-content-center mt-5">
                <div className="col-lg-4 col-md-6 col-sm-6">
                    <div className="card shadow">
                        <div className="card-title text-center border-bottom" style={{"backgroundColor": backgroundColorHexValue, "borderRadius": "3px"}}>
                            <a href='/' >
                                <img 
                                    src={logoURL}
                                    style={{ width: '200px', margin: '20px' }}
                                    className={'nav-logo'} alt={"Home | Oklahoma State Department of Health Immunization Service"}
                                />
                            </a>
                            <div className="row mb-3">
                                <div className="col-3"></div>
                                <div className="col-6">
                                    <LanguageSwitcher onChange={(language) => setSelectedLanguage(language)}/>
                                </div>
                                <div className="col-3"></div>
                            </div>
                        </div>
                        <div className="card-body">
                            <form onSubmit={(e) => handleSubmit(onSubmit,onError)(e).catch((e)=>{
                                console.error(' catch ',e)
                            })}>
                                <div className="mb-4" id="username-div">
                                    <label htmlFor="username" className="form-label">{t('Email')}</label>
                                    <input type="text" aria-invalid={!!errors.username} aria-describedby="usernameError" className="form-control"
                                        id="username" name={"username"}
                                        {...register("username", {
                                            required: 'Email is Required',
                                            pattern: {
                                                value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                                message: 'Please verify email syntax.'
                                            },
                                            disabled: (!!userContext && !formIsEmailPasswordReset)
                                        })}
                                        onChange={(e) => {
                                            setEmail(e.target.value.trim());
                                            setForm({ ...form, 'username': e.target.value.trim() });
                                        }}
                                    />
                                    {/* TODO: add t to error.username.message */}
                                    {errors.username && <small id="usernameError" style={{color:'red'}}className={"error"}>{t(`${errors.username.message}`)}</small>}
                                </div>
                        {/** Login => PASSWORD FIELD**/}
                                {formIsLogin &&
                                    <div className="mb-4" id="login-password-div" style={{position:'relative'}}>
                                        <label htmlFor="password" className="form-label">{t("Password")}</label>
                                        <input type="password" className="form-control" aria-invalid={!!errors.password} aria-describedby="passwordError" name={"password"}
                                            style={{ paddingRight: '60px' }}
                                            {...register("password", {
                                                required: 'Password is Required'
                                            })}
                                            id="password"
                                            onChange={(e) => {
                                                setPassword(e.target.value);
                                                setForm({ ...form, 'password': e.target.value });
                                            }}
                                        />
                                        <span style={{ position: 'absolute', right: '4px', top: '37px' }}>
                                            <AiFillEye color={aiFillEyeColor ? '#00539B' : 'grey'} size={25} onClick={(e) => togglePassword(e, 'password')} />
                                        </span>
                                        {errors.password && <small style={{ color: 'red' }} className={"error"}>{t(`${errors.password.message}`)}</small>}
                                    </div>
                                }
                        {/** EnrollMFA => PASSWORD &PHONE NUMBER FIELD**/}
                                {formIsEnrollMFA && 
                                <div id="enrollmfa-password-phonenumber-div">
                                    <div className="mb-4">
                                        <label htmlFor="password" className="form-label">{t("Password")}</label>
                                        <input type="password" className="form-control" name={"password"}
                                            style={{ paddingRight: '60px' }}
                                            {...register("password", {
                                                required: 'Password is Required',
                                            })}
                                            id="password"
                                            onChange={(e) => {
                                                setPassword(e.target.value);
                                                setForm({ ...form, 'password': e.target.value });
                                            }}
                                        />
                                        <span style={{ position: 'absolute', right: '24px', top: '248px' }}>
                                            <AiFillEye color={aiFillEyeColor ? '#00539B' : 'grey'} size={25} onClick={(e) => togglePassword(e, 'password')} />
                                        </span>
                                        {errors.password && <small style={{ color: 'red' }} className={"error"}>{t(`${errors.password.message}`)}</small>}
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="phoneNumber" className="form-label">{t("Phone Number")}</label>
                                            <PhoneInput
                                                id='phoneNumber'
                                                name={'phoneNumber'}
                                                {...register("phoneNumber")}
                                                placeholder={t("Enter phone number")}
                                                value={phoneNumber}
                                                onChange={(e) =>{ 
                                                    setPhoneNumber(e); 
                                                    setForm({...form, 'phoneNumber': e})
                                                }}
                                                defaultCountry="US"
                                            />
                                            {errors.phoneNumber && <small id="passwordError" style={{ color: 'red' }} className={"error"}>{t(`${errors.phoneNumber.message}`)}</small>}
                                    </div>
                                </div>
                                }
                        {/** resetPassword PASSWORD FIELD **/}
                                {( (formIsPasswordReset || formIsEmailPasswordReset) && pswdConfigs != null ) &&
                                    <div id="resetpassword-password-confirm-password-div">
                                        <div className="mb-4" style={{position:'relative'}}>
                                            <label htmlFor="password" className="form-label">{t("New Password")}</label>
                                            <input type="password" className="form-control" name={"password"}
                                                style={{ paddingRight: '60px' }}
                                                {...register("password", pswdConfigs )}
                                                id="password"
                                                onChange={(e) => {
                                                    setPassword(e.target.value);
                                                    setForm({ ...form, 'password': e.target.value });
                                                }}
                                            />
                                            <span style={{ position: 'absolute', right: '4px', top: '37px' }}>
                                                <AiFillEye color={aiFillEyeColor ? '#00539B' : 'grey'} size={25} onClick={(e) => togglePassword(e, 'password')} />
                                            </span>
                                            {/* TODO: add t to error.password.message */}
                                            {errors.password && <small style={{ color: 'red' }} className={"error"}>{t(`${errors.password.message}`)}</small>}
                                        </div>
                                        <div className="mb-4" style={{position: 'relative'}}>
                                            <label htmlFor="confirmPassword" className="form-label">{t("Confirm Password")}</label>
                                            <input type="password" className="form-control" name={"confirmPassword"}
                                                style={{ paddingRight: '60px' }}
                                                {...register("confirmPassword", pswdConfigs)}
                                                id="confirmPassword"
                                                onChange={(e) => {
                                                    setForm({ ...form, 'confirmPassword': e.target.value })
                                                }}
                                            />
                                            <span style={{ position: 'absolute', right: '4px', top: '37px' }}>
                                                <AiFillEye color={aiFillEyeColor2 ? '#00539B' : 'grey'} size={25} onClick={(e) => togglePassword(e, 'confirmPassword')} />
                                            </span>
                                            {errors.confirmPassword &&
                                                <small className={"error"} style={{ color: 'red' }}>{t(`${errors.confirmPassword.message}`)}</small>
                                            }
                                            {watch("confirmPassword") !== watch("password") &&
                                                getValues("confirmPassword") ?
                                                (<small style={{ color: 'red' }} className={"error"}>{t("Passwords do not match")}</small>) :
                                                null
                                            }
                                        </div>
                                    </div>
                                }
                    {/* REGISTER PASSWORD/CONFIRM PASSWORD */}
                                { (formIsRegister && pswdConfigs !== null ) &&
                                    <div id="resetpassword-password-confirm-password-div">
                                        <div className="mb-4" style={{position:'relative'}}>
                                            <label htmlFor="password" className="form-label">{t("Password")}
                                                <section className={"d-inline-block tooltipButton"}>
                                                <FaInfoCircle
                                                    tabIndex={0}
                                                    aria-label="Password Requirements"
                                                    role="button"
                                                    className={"ml-1 d-inline-block"}
                                                    size={25}
                                                    color={"#21212E"}
                                                    // onMouseOver={() => showModalNoOutsideClick(HintModal.ID)}
                                                    // onClick={() => showModalNoOutsideClick(HintModal.ID)}
                                                    ></FaInfoCircle>
                                                    <span className="tooltiptext" aria-live="polite">{hintModal.hintContent}</span>
                                                </section>
                                            </label>
                                            <input type="password" className="form-control" aria-invalid={!!errors.password} aria-describedby="passwordError" name={"password"}
                                                style={{ paddingRight: '60px' }}
                                                {...register("password", pswdConfigs )}
                                                id="password"
                                                onChange={(e) => {
                                                    setPassword(e.target.value);
                                                    setForm({ ...form, 'password': e.target.value });
                                                }}
                                            />
                                            <span style={{ position: 'absolute', right: '4px', top: '37px' }}>
                                                <AiFillEye color={aiFillEyeColor ? '#00539B' : 'grey'} size={25} onClick={(e) => togglePassword(e, 'password')} />
                                            </span>
                                            {errors.password && <small id="passwordError" style={{ color: 'red' }} className={"error"}>{t(`${errors.password.message}`)}</small>}
                                        </div>
                                        <div className="mb-4" style={{position: 'relative'}}>
                                            <label htmlFor="confirmPassword" className="form-label">{t("Confirm Password")}</label>
                                            <input type="password" className="form-control" aria-invalid={!!errors.confirmPassword} aria-describedby="confirmPasswordError" name={"confirmPassword"}
                                                style={{ paddingRight: '60px' }}
                                                {...register("confirmPassword", pswdConfigs)}
                                                id="confirmPassword"
                                                onChange={(e) => {
                                                    setForm({ ...form, 'confirmPassword': e.target.value })
                                                }}
                                            />
                                            <span style={{ position: 'absolute', right: '4px', top: '37px' }}>
                                                <AiFillEye color={aiFillEyeColor2 ? '#00539B' : 'grey'} size={25} onClick={(e) => togglePassword(e, 'confirmPassword')} />
                                            </span>
                                            {errors.confirmPassword &&
                                                <small id="confirmPasswordError" className={"error"} style={{ color: 'red' }}>{t(`${errors.confirmPassword.message}`)}</small>
                                            }
                                            {watch("confirmPassword") !== watch("password") &&
                                                getValues("confirmPassword") ?
                                                (<small style={{ color: 'red' }} className={"error"}>{t("Passwords do not match")}</small>) :
                                                null
                                            }
                                        </div>
                                        

                                            <div className="mb-4" id="firstname-div" style={{position: 'relative'}}>
                                                <label htmlFor="firstName" className="form-label">{t("First Name")}</label>
                                                <input type="text" aria-invalid={!!errors.firstName} aria-describedby="firstNameError" className="form-control"
                                                    id="firstName" name={"firstName"}
                                                    {...register("firstName", {
                                                        required: t('First Name is Required'),
                                                    })}
                                                    onChange={(e) => {
                                                        setFirstName(e.target.value.trim());
                                                        setForm({ ...form, 'firstName': e.target.value });
                                                    }}
                                                />
                                                {errors.firstName && <small id="firstNameError" style={{ color: 'red' }} className={"error"}>{t(`${errors.firstName.message}`)}</small>}
                                            </div>

                                            <div className="mb-4" id="lastname-div" style={{position: 'relative'}}>
                                                <label htmlFor="lastName" className="form-label">{t("Last Name")}</label>
                                                <input type="text" aria-invalid={!!errors.lastName} aria-describedby="lastNameError" className="form-control"
                                                    id="lastName" name={"lastName"}
                                                    {...register("lastName", {
                                                        required: t('Last Name is Required'),
                                                    })}
                                                    onChange={(e) => {
                                                        setLastName(e.target.value.trim());
                                                        setForm({ ...form, 'lastName': e.target.value });
                                                    }}
                                                />
                                                {errors.lastName && <small id="lastNameError" style={{ color: 'red' }} className={"error"}>{t(`${errors.lastName.message}`)}</small>}
                                            </div>

                                    </div>
                                }
                        {/** Action Button **/}
                                <div className="d-flex justify-content-center">
                                    <></>
                                    <button type="submit" 
                                        id="login-button"
                                        name="login-button"
                                        disabled={
                                            ((form_action ==='resetPassword' || form_action ==='action' || form_action === 'register') && (watch("confirmPassword") !== watch("password")) ) ||
                                            (form_action ==='enrollMFA' && !phoneNumber )
                                        }
                                        className="btn btn-lg text-light width-100" style={{backgroundColor: buttonColorHexValue}}>
                                            {formIsPasswordReset || formIsEmailPasswordReset ? t('Reset Password') :
                                             formIsEnrollMFA ? t('Enroll MFA') :
                                             formIsRegister ? t('Register') : 
                                             formIsLogin ? t('Login') : 
                                             'Streamline'}
                                    </button>
                                    <></>
                                </div>
                                

                                { form_action === 'login' && 
                                <div className="mt-2 form-check">
                                    <span className={"form-check-label"}>
                                    <input className={"form-check-input"} 
                                           type="checkbox" id="rememberMe"
                                           onChange={(e) => setRememberMe(e.target.checked) }
                                           checked={rememberMe}
                                    /> {t("Keep Me Signed In")}
                                    </span> 
                                </div>
                                }
                            </form>

                            {form_action !== 'register' &&
                            <>
                                <div className={"signin-link card-footer ms-auto d-flex justify-content-between mt-5"}>
                                    <a role="button" style={{ color: buttonColorHexValue }} href={"/register"}>{t("Register")}</a>
                                    <a role="button" style={{ color: buttonColorHexValue }} href={"/forgotPassword"}>{t("Forgot Password?")}</a>
                                </div>
                                
                            </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
    )
}